import { ExceptionApis, ExceptionCodes } from './StatusCodes';
// IMAGE
export var NEW_LOGIN_LOGO = require('@/public/static/images/img_login_logo.png').default;
export var MY_PROFILE_IMAGE = require('@/public/static/images/img_my_profile.png').default;
export var CREATOR_DEFAULT_IMAGE = require('@/public/static/images/img_creator_default.png').default;
export var PRODUCTION_IMAGES = {
    NA7017: require('@/public/static/images/img_package_01.png').default,
    NA00007017: require('@/public/static/images/img_package_01.png').default,
    NA7246: require('@/public/static/images/img_package_02.png').default,
    NA00007246: require('@/public/static/images/img_package_02.png').default,
};
export var EVENT_WINNER_IMAGE = require('@/public/static/images/img_raffle.png').default;
export var APP_INSTALL = require('@/public/static/images/popup_img.png').default;
export var ICO_CREATOR_HOME = require('@/public/static/images/ico_creator.png').default;
export var ICO_CREATOR_HOME_WHITE = require('@/public/static/images/ico_creator_white.png').default;
// LABEL
export var EXCEPT_NAVIGATION = [
    'login',
    'video',
    'tutorial',
    '/setting/notices/[id]',
    '599',
    'join_sktevent',
    '/present/recieve/[id]',
];
// MESSAGE
export var DEFAULT_ERROR_MESSAGE = '일시적인 장애로 페이지를 표시할 수 없습니다. 잠시 후 다시 시도해 주세요.';
export var TIMEOUT_MESSAGE = '일시적으로 서비스 연결이 지연되고 있습니다. 잠시 후 다시 시도해 주세요.';
export var CONFIRM_JOIN_VOUCHER_FOR_ADOT = 'V 컬러링 이용권에 가입하면 A. 연동은 해제됩니다.\n이용권 가입을 계속 진행하시겠어요?';
export var CONFIRM_CHANGE_VOUCHER_FOR_ADOT = 'V 컬러링 이용권을 변경하면 A. 연동은 해제됩니다.\n이용권 가입을 계속 진행하시겠어요?';
// NUMBER
export var COUPON_LENGTH = 8;
export var DEFAULT_LIST_SIZE = 20;
export var DEFAULT_TIMEOUT = 30000;
export var HALF_HOUR = 30 * 60 * 1000;
export var ONE_HOUR = 60 * 60 * 1000;
export var ONE_MINUTE = 60 * 1000;
export var ONE_DAY = 60 * 60 * 1000 * 24;
export var VALID_SMS_SECONDS = 180;
export var DEFAULT_UPLOADING_DURATION_FOR_APP = 15;
export var DEFAULT_UPLOADING_DURATION_MIN = 3;
export var DEFAULT_UPLOADING_DURATION_MAX = 60;
export var DEFAULT_UPLOADING_SIZE_MAX_WIDTH = 1920;
export var DEFAULT_UPLOADING_SIZE_MAX_HEIGHT = 1080;
export var UPLOADING_TICK = 0.1;
export var AUTO_COLORING_SEARCH_MAX = 100;
// REGIX
export var PHONE_NUMBER_REGEX = /^(01[1|6-9]\d{3,4}|010\d{4})\d{4}$/;
export var ONLY_WORD_AND_NUMBER_REGEX = /^[a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣0-9\s\u318D\u119E\u11A2\u2025\u00B7\uFE55\u4E10]*$/;
export var COUPON_REGEX = /^[a-zA-Z0-9]{0,8}$/;
export var SPECIAL_CHAR_REGEX = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/g;
// URL
export var VOICE_COLORING_URL_SKT = 'http://www.tcoloring.com';
export var VOICE_COLORING_URL_KT = 'https://ringtoyou.kt.com/ ';
export var VOICE_COLORING_URL_LGU = 'http://www.musicbellring.com/';
export var VOICE_COLORING_URL_LGU_APP = 'https://www.musicon.co.kr/if/landing.jsp?scheme=ubellring&package_name=com.lgu&intent=top100/';
export var VOICE_COLORING_APP_INTENT = 'intent://tcoloring?linktype=dlink&dlink=https%3A%2F%2Fwww.tcoloring.com%2Findex.jsp;#Intent;scheme=com.real.tcolorring;package=com.real.tcolorring;S.market_referrer=linktype=dlink&dlink=https%3A%2F%2Fwww.tcoloring.com%2Findex.jsp;end';
export var ANDROID_PACKAGE = 'com.sktelecom.vcoloring';
export var MARKET_URL = "https://play.google.com/store/apps/details?id=".concat(ANDROID_PACKAGE);
export var BUSINESS_INFO_URL = 'http://www.ftc.go.kr/bizCommPop.do?wrkr_no=1048137225';
export var APP_LINK = process.env.NEXT_PUBLIC_MODE === 'QA' ? 'https://www-qa.vcoloring.com' : 'https://www.vcoloring.com';
export var DEEP_LINK = 'https://link.vcoloring.com';
export var DEEP_LINK_MARKET = "".concat(DEEP_LINK, "/mk");
export var FIREBASE_SHORT_API = "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=".concat(process.env.NEXT_PUBLIC_FB_API_KEY);
export var APPS_FLYER_LINK = 'http://app.appsflyer.com';
export var APP_ONE_LINK = 'https://vcoloring.onelink.me/Ac4O';
// VALUE
export var NO_DISPLAY_ERROR_CODE_LIST = Object.values(ExceptionCodes);
export var NO_DISPLAY_ERROR_API_LIST = Object.values(ExceptionApis);
// VARIABLE
export var NONE = 'none';
export var DISABLED_POPUP_KEY = 'vrbt-disabled-popups';
export var IS_FETCHING_REFRESH = false;
// Subscribed service type
export var VCOLORING = 'VCOLORING';
export var VBIZRING = 'VBIZRING';
// LocalStorage key
export var SEARCH_KEY = 'vrbt-searches';
export var SEARCH_AUTO_COLORING_KEY = 'vrbt-searches-auto-coloring';
export var SEARCH_AUTO_COLORING_STORE_KEY = 'vrbt-searches-auto-coloring-store';
export var DISABLED_COUPON = 'vrbt-disabled-coupon';
export var DISABLED_UPLOAD_UGC_GUIDE = 'vrbt-disabled-upload-ugc-guide';
export var DISABLED_UPLOAD_TIME_GUIDE = 'vrbt-disabled-upload-time-guide';
// CUSTOM_EVENT
export var POPUP_INITIAL_OPEN = 'vrbt-popup-initial-open';
export var POPUP_ALL_CLOSE = 'vrbt-popup-all-close';
export var PROMOTION_AGREE = {
    overFourteenAgree: true,
    personalInfoCollectionAndUsageAgree: true,
    personalInfoProcessingAndConsignmentAgree: true,
};
